import React from 'react'
import { createRoot } from 'react-dom/client'
import { Buffer } from 'buffer'
import { Amplify } from 'aws-amplify'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split,
} from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { setContext } from '@apollo/client/link/context'
import { Chart as ChartJS, ArcElement } from 'chart.js'

import Application from './Application'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import awsConfig from './aws-exports'
import env from '@app/environment'
import { getMainDefinition } from '@apollo/client/utilities'
import '@app/libraries/i18n.ts'
import { PostHogProvider } from 'posthog-js/react'
import { getToken } from './helpers/auth'

Amplify.configure(awsConfig)

ChartJS.register(ArcElement)

const httpLink = createUploadLink({
  uri: env.API_URL,
})

const httpLinkImageUpload = createUploadLink({
  uri: env.API_URL_IMAGE_UPLOAD,
})

const imageUploadLinkSplit = split(
  (operation) => operation.getContext().operation === 'imageUpload',
  httpLinkImageUpload,
  httpLink
)

const wsLink = new GraphQLWsLink(
  createClient({
    url: env.SUBSCRIPTION_URL,
    connectionParams: {
      authToken: getToken(),
    },
  })
)

const authLink = setContext(async (_, { headers }) => {
  try {
    const authType = (localStorage.getItem('auth_type') || '').trim().toLowerCase()
    const incognitoMode = sessionStorage.getItem('incognitoMode') ?? ''
    const token = await getToken()
    const tokenType = authType === 'telegram' ? 'Telegram' : authType === 'magic_link' ? 'MagicLink' : 'Bearer'

    return {
      headers: {
        ...headers,
        authorization:  `${tokenType} ${token}`,
        'X-Incognito-Mode': incognitoMode,
      },
    }
  } catch (e) {
    return headers
  }
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  authLink.concat(imageUploadLinkSplit)
)

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    dataIdFromObject: o => (o._id ? `${o.__typename}:${o._id}`: null)
  }),
})

const posthogClientOptions = {
  api_host: env.PUBLIC_POSTHOG_HOST,
}

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={env.PUBLIC_POSTHOG_KEY}
      options={posthogClientOptions}
    >
      <ApolloProvider client={client}>
        <Application />
      </ApolloProvider>
    </PostHogProvider>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister()

serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()