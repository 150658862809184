import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Screen } from '@app/components'
import { Button, Divider } from '@app/ui'
import { useTranslation } from 'react-i18next'
import { DomainTitleComponent } from './Entry'
import { getFirebaseAuth } from '@app/firebase'
import { ButtonSize, ButtonType } from '@app/types'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@app/constants'


import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth'
import { UserOnboardingStages } from '@app/graphql'
import { AuthHelper } from '@app/helpers'
const auth = getFirebaseAuth()

interface MagicLinkScreenProps { }

const MagicLinkSentScreen: FC<MagicLinkScreenProps> = () => {

  const navigate = useNavigate()


  const { t } = useTranslation()

  return (
    <>
      <LoginScreenStyled title={t('login.authorization')}>
        <Divider size="logoTopSpace" />
        <ImageIcon className="logotype" src="/assets/branding/logotype-entry-x.png" alt="Votebetting" />
        <DomainTitleComponent />
        <DomainSubtitle>Powering tipsters.</DomainSubtitle>
        <DomainSubtitle>Engaging users.</DomainSubtitle>
        <Divider size="logoTopSpace" />
        <Divider size="logoTopSpace" />
        <Great>{t('auth.great')}</Great>
        <p>{t('check.justFewClicks')}</p>
        <Divider size="huge" />

        <Button
          size={ButtonSize.big}
          type={ButtonType.buttonColor}
          fluid
          onClick={() => console.log('click')}
          // onClick={() => navigate(ROUTES.loginPersonalInfo.full)}
          textColor="var(--r-color-white)"
        >
          <p>{t('common.continue')}</p>
        </Button>
        <Divider expanded />
      </LoginScreenStyled>
    </>
  )
}

const LoginScreenStyled = styled(Screen)`
  text-align: center;
  background-color: var(--r-color-background-gray);

  .logotype {
    width: 180px;
    display: block;
    margin: auto;
  }
  .incognito-icon {
    width: 32px;
  }
  iframe {
    display: none !important;
  }
`

const ImageIcon = styled.img`
  padding: 20px;

`
const LoginAction = styled.p`
  padding: 20px 0;
`

const DomainTitle = styled.p`
  font-weight:  900;
  font-style:   italic;
  font-size:    30.41px;
  font-family:  Nunito-Bold;
  line-height:  34.65px; 

`
const MagicLinkDescription = styled.p`
  text-align: left;
  font-size: var(--font-size-extra-small);
`
const CreateAccount = styled.p`
  text-align: center;
  font-size: var(--font-size-big);
  color: var(--r-color-gray-darker);
  font-weight: 700;
  padding: 20px 0;
`
const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    padding-left: 10px;
      font-size: var(--font-size-medium);
  }
`
const DomainSubtitle = styled.p`
  text-align: center;
  font-size:  16px;
  color: #3A9D91;
  font-weight: 900;
`

const Great = styled.p`
  text-align: center;
  font-size: var(--font-size-medium-extra-big);
  color: var(--r-color-gray-darker);
  font-weight: 900;
  padding: 20px 0;
`
export default MagicLinkSentScreen


