import { MutableRefObject, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useCallback } from 'react'

import {
  Notifications,
  useGetNotificationsLazyQuery,
  useGetNotificationsAndMessagesCountLazyQuery,
  useUserChannelsLazyQuery,
  useGetTournamentsBySportIdsLazyQuery,
  Tournament,
  useGetTournamentsByIdsLazyQuery,
  MultitipDetailed,
  TipDetailed,
  TipsFilterInput,
  useGetTipListLazyQuery,
  SportEventType,
  useGetEventsByTournamentAndDateLazyQuery,
  UpdateUserDocument,
} from '@app/graphql'
import { MatchEvent, TourStages } from '@app/types'
import { CurrentUserState } from '@app/storage'
import { useMutation } from '@apollo/client'
import { checkDataExist, deleteData, getStoreData, Stores } from './db'

export const useNodePositionX= <T extends HTMLElement>() => {
  const ref = useRef<T>(null)
  const [ positionX, setPositionX ] = useState(0)

  useLayoutEffect(() => {
    const update = () => {
      if(ref?.current) {
        setPositionX(ref?.current?.getBoundingClientRect()?.left)
      }
    }
    update()
    window.addEventListener('resize', update)
    return () => {
      window.removeEventListener('resize', update)
    }
  }, [])
  return {ref, positionX}
}

export const useScrollLock = () => {
  const lockScroll = useCallback(() => {
    const scrollPosition = window.scrollY
    sessionStorage.setItem('scrollPosition', scrollPosition.toString())
    document.body.style.position = 'fixed'
    document.body.style.overflow = 'hidden'
    document.body.style.top = `-${scrollPosition}px`
    document.body.style.width = '100%'
  }, [])

  const unlockScroll = useCallback(() => {
    document.body.style.removeProperty('overflow')
    document.body.style.removeProperty('position')
    document.body.style.removeProperty('top')
    document.body.style.removeProperty('width')
    const scrollPosition = sessionStorage.getItem('scrollPosition')
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition))
      sessionStorage.removeItem('scrollPosition')
    }
  }, [])

  return {
    lockScroll,
    unlockScroll,
  }
}

/**
 * Used for animations support in conditional rendering.
 * @param { Boolean } flag boolean variable.
 * @param { Number } delayTime delay time in ms.
 * @returns { Boolean } result after delay.
 */
export const useDelayedState = (flag: boolean, delayTime: number): boolean => {
  const [ status, setStatus ] = useState(false)

  useEffect(() => {
    let timeoutId

    if (flag && !status) {
      setStatus(true)
    } else if (!flag && status) {
      timeoutId = setTimeout(() => setStatus(false), delayTime)
    }
    return () => clearTimeout(timeoutId)
  }, [ status, flag, delayTime ])

  return status
}

/**
 * Used for checking element visibility with the help of Intersection Observer API.
 * @param { MutableRefObject<HTMLElement> } ref element ref.
 * @param { Number } rootMargin bottom margin number in px.
 * @param { Boolean } loading loading status.
 * @returns { Boolean } element visibility status.
 */
export const useObserver = (
  ref: MutableRefObject<HTMLElement>,
  rootMargin?: number,
  loading?: boolean
): boolean => {
  const [ isIntersecting, setIntersecting ] = useState(false)

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([ entry ]) => setIntersecting(entry.isIntersecting),
        { rootMargin: `0px 0px ${rootMargin || 0}px 0px` }
      ),
    [ ref ]
  )

  useEffect(() => {
    if (ref.current && !loading) {
      observer.observe(ref.current)
    }

    return () => {
      observer.disconnect
    }
  }, [ ref.current, loading ])

  return isIntersecting
}

interface NotificationsAndMessagesCountType {
  notificationsCount: number;
  messagesCount: number;
}

/**
 * Used for getting notifications and count of notifications / messages
 */
export const useLoadNotifications = () => {
  const [ loadingCount, setLoadingCount ] = useState(false)
  const [ loadingNotifications, setLoadingNotifications ] = useState(false)
  const [ loadNotifications ] = useGetNotificationsLazyQuery({
    fetchPolicy: 'network-only',
  })
  const [ loadNotificationsAndMessagesCount ] =
    useGetNotificationsAndMessagesCountLazyQuery({
      fetchPolicy: 'network-only',
    })
  const [ getUserChannels ] = useUserChannelsLazyQuery()

  const getDefaultCounts = (): NotificationsAndMessagesCountType => ({
    notificationsCount: 0,
    messagesCount: 0,
  })

  const getNotifications = async (options?: {
    limit?: number;
    skip?: number;
    isNew?: boolean;
  }): Promise<Notifications> => {
    try {
      setLoadingNotifications(true)
      const userChannelsResponse = await getUserChannels()
      const { userChannels } = userChannelsResponse.data
      if (!userChannels.length) return { count: 0, notifications: []}
      const notificationsResponse = await loadNotifications({
        variables: {
          channelIds: userChannels.map((ch) => ch.id),
          limit: options?.limit || 10,
          skip: options?.skip || 0,
          isNew: options?.isNew || false,
        },
      })
      return (
        notificationsResponse.data.notifications || {
          count: 0,
          notifications: [],
        }
      )
    } catch (e) {
      return { count: 0, notifications: []}
    } finally {
      setLoadingNotifications(false)
    }
  }

  const getNotificationsAndMessagesCount = async (options?: {
    isNewNotifications?: boolean;
  }): Promise<NotificationsAndMessagesCountType> => {
    try {
      setLoadingCount(true)
      const userChannelsResponse = await getUserChannels()
      const { userChannels } = userChannelsResponse.data
      if (!userChannels.length) return getDefaultCounts()

      const countResponse = await loadNotificationsAndMessagesCount({
        variables: {
          channelIds: userChannels.map((ch) => ch.id),
          limit: 0,
          skip: 0,
          isNew: options?.isNewNotifications || false,
        },
      })
      const { notifications, unreadChatsCount } = countResponse.data

      return {
        notificationsCount: notifications.count || 0,
        messagesCount: unreadChatsCount,
      }
    } catch (e) {
      return getDefaultCounts()
    } finally {
      setLoadingCount(false)
    }
  }

  return {
    loading: loadingNotifications || loadingCount,
    getNotifications,
    getNotificationsAndMessagesCount,
  }
}

const STANDARD_LIMIT = 10
/**
 * Used to get competitions by sport ids with pagination and search
 */
export const useLoadCompetitions = () => {
  const [ initLoading, setInitLoading ] = useState(false)
  const [ paginationLoading, setPaginationLoading ] = useState(false)
  const [ hasError, setHasError ] = useState(false)
  const [ searchString, setSearchString ] = useState<string>(null)
  const [ totalCount, setTotalCount ] = useState(-1)
  const [ competitionsData, setCompetitionsData ] = useState<Tournament[]>([])
  const [ sportIds, setSportIds ] = useState<string[]>([])
  const [ getCompetitions ] = useGetTournamentsBySportIdsLazyQuery()

  /* SEARCH WITH DELAY */
  useEffect(() => {
    const timeout = setTimeout(() => {
      initLoadCompetitions()
    }, 500)

    return () => clearTimeout(timeout)
  },[ searchString ])
  /* SPORT IDS WERE CHANGED */
  useEffect(() => {
    if (sportIds?.length) {
      initLoadCompetitions()
    }
  },[ sportIds ])

  const loadCompetitions = async (input: {
    ids: string[];
    limit: number;
    skip: number;
    search: string;
  }) => {
    try {
      const result = await getCompetitions({ variables: input })
      const { tournaments, totalCount } = result.data.tournamentsBySportIds

      setTotalCount(totalCount)
      return tournaments as Tournament[]
    } catch (e) {
      console.error('Load competitions Error: ', e)
      setHasError(true)
      return []
    }
  }

  const initLoadCompetitions = async () => {
    if (initLoading || !sportIds?.length || paginationLoading) return

    setInitLoading(true)
    const input = { ids: sportIds, limit: STANDARD_LIMIT, skip: 0, search: searchString }
    const competitions = await loadCompetitions(input)
    setCompetitionsData(competitions)
    setInitLoading(false)
  }

  const onNextPage = async () => {
    if (
      initLoading ||
      !sportIds?.length ||
      paginationLoading ||
      competitionsData?.length >= totalCount
    ) return

    setPaginationLoading(true)
    const limit = totalCount - competitionsData?.length <= STANDARD_LIMIT ?
      totalCount - competitionsData?.length :
      STANDARD_LIMIT
    const input = { ids: sportIds, limit, skip: competitionsData?.length || 0, search: searchString }
    const competitions = await loadCompetitions(input)
    if (competitions?.length) {
      setCompetitionsData([ ...competitionsData, ...competitions ])
    }
    setPaginationLoading(false)
  }

  return {
    hasMore: competitionsData?.length < totalCount,
    hasError,
    searchString,
    competitionsData,
    initLoading,
    paginationLoading,
    setSearchString,
    setSportIds,
    onNextPage,
  }
}

export const useLoadCompetitionNames = () => {
  const [ loading, setLoading ] = useState(false)
  const [ competitionNames, setCompetitionNames ] = useState('')
  const [ favoriteCompetitions, setFavoriteCompetitions ] = useState<string[]>([])

  const [ getTournamentNames ] = useGetTournamentsByIdsLazyQuery()

  useEffect(() => {
    if (favoriteCompetitions?.length) {
      loadCompetitionNames()
    }
  }, [ favoriteCompetitions ])

  const loadCompetitionNames = async () => {
    try {
      setLoading(true)

      const result = await getTournamentNames({
        variables: {
          ids: favoriteCompetitions,
        },
      })

      setCompetitionNames(result?.data?.tournamentsByIds?.reduce((str, elem) => {
        if (!str) return `${elem.name}`
        return `${str}, ${elem.name}`
      }, '') || '')
    } catch (e) {
      console.error('Load competition names failed: ', e)
    } finally {
      setLoading(false)
    }
  }

  return {
    competitionNames,
    loading,
    setFavoriteCompetitions,
  }
}

/**
 * Used to load list of tips
 * @param filter {TipsFilterInput}
 * @returns List of tips and other params
 */
export const useLoadTipsList = (filter?: TipsFilterInput) => {
  const [ initLoading, setInitLoading ] = useState(false)
  const [ paginationLoading, setPaginationLoading ] = useState(false)
  const [ hasMore, setHasMore ] = useState(true)
  const [ tipsList, setTipsList ] = useState<Array<MultitipDetailed | TipDetailed>>([])
  const [ filterInput, setFilterInput ] = useState(filter)

  const [ getTipList ] = useGetTipListLazyQuery()

  useEffect(() => {
    const load = async () => {
      try {
        setInitLoading(true)
        await loadTips(filterInput, STANDARD_LIMIT, 0, true)
      } catch (e) {
        console.error('Initial tips list loading Error: ', e)
      } finally {
        setInitLoading(false)
      }
    }

    if (filterInput) {
      load()
    }
  }, [ filterInput ])

  const loadTips = async (f: TipsFilterInput, limit: number, skip: number, init: boolean) => {
    const result = await getTipList({
      variables: {
        input: f,
        limit,
        skip,
      },
    })
    const tips = result.data.detailedTipsAndMultitips as Array<MultitipDetailed | TipDetailed>
    if (tips?.length) {
      setHasMore(true)
      setTipsList(prev => {
        if (init) return [ ...tips ]
        return [ ...prev, ...tips ]
      })
    } else {
      setHasMore(false)
      if (init) setTipsList([])
    }
  }

  const onNextPage = async () => {
    if (initLoading || paginationLoading || !filterInput || !hasMore) return

    try {
      setPaginationLoading(true)
      await loadTips(filterInput, STANDARD_LIMIT, tipsList?.length || 0, false)
    } catch (e) {
      console.error('Next page tips list loading Error: ', e)
    } finally {
      setPaginationLoading(false)
    }
  }

  return {
    hasMore,
    tipsList,
    initLoading,
    paginationLoading,
    loadTips,
    onNextPage,
    setFilterInput,
  }
}

interface LoadMatchesInputType {
  tournamentId?: string;
  eventsDate: string;
  sportId: string;
}
/**
 * Used to load list of matches
 * @returns List of matches and other params
 */
export const useLoadMatchesList = () => {
  const [ initLoading, setInitLoading ] = useState(false)
  const [ paginationLoading, setPaginationLoading ] = useState(false)
  const [ hasMore, setHasMore ] = useState(true)
  const [ matchesList, setMatchesList ] = useState<Array<MatchEvent>>([])

  const [ getMatches ] = useGetEventsByTournamentAndDateLazyQuery({ fetchPolicy: 'no-cache' })
  const loadMatches = async (init: boolean, input: LoadMatchesInputType) => {
    const skip = init ? 0 : (matchesList?.length || 0)
    const dbCursor = `${input.eventsDate}-${true}-${input.sportId}-${skip}-${STANDARD_LIMIT}`
    let result
    if(await checkDataExist(Stores.MATCHES, dbCursor)){
      result = await getStoreData(Stores.MATCHES, dbCursor) as any
      deleteData(Stores.MATCHES, dbCursor)
    } else {
      result = await getMatches({
        variables: {
          ...input,
          forDefaultBookie: true,
          limit: STANDARD_LIMIT,
          skip,
        },
      })
    }
    const Oldmatches = result.data.sportEventsByTournamentIdAndDate as Array<SportEventType>
    const matchDifferentiator = match => match.id
    const prevMatchIds = matchesList.map(matchDifferentiator)
    const unseenMatches = Oldmatches.filter(match => !prevMatchIds.includes(matchDifferentiator(match)))
    if (unseenMatches?.length || Oldmatches?.length) {
      const matches = unseenMatches?.length? unseenMatches: Oldmatches
      setHasMore(Oldmatches.length >= STANDARD_LIMIT)
      setMatchesList(prev => {
        if (init) return [ ...matches ]
        return [ ...prev, ...matches ]
      })
    } else {
      setHasMore(false)
      if (init) setMatchesList([])
    }
  }

  const onInitLoad = async (input: LoadMatchesInputType) => {
    if (initLoading) return

    try {
      setInitLoading(true)
      await loadMatches(true, input)
    } catch (e) {
      console.error('Init load matches list Error: ', e)
    } finally {
      setInitLoading(false)
    }
  }

  const onNextPage = async (input: LoadMatchesInputType) => {
    if (initLoading || paginationLoading || !hasMore) return

    try {
      setPaginationLoading(true)
      await loadMatches(false, input)
    } catch (e) {
      console.error('Next page matches list loading Error: ', e)
    } finally {
      setPaginationLoading(false)
    }
  }

  return {
    hasMore,
    matchesList,
    initLoading,
    paginationLoading,
    onInitLoad,
    onNextPage,
  }
}

type TourStageNameArg = keyof TourStages
type TourStageUpdateFn = ({current, next}: {current: TourStageNameArg, next: TourStageNameArg}) => void

export const useTourStage = (stageName: TourStageNameArg): [TourStageNameArg, TourStageUpdateFn] => {
  const [ updateUser ] = useMutation(UpdateUserDocument)
  const [ currentStage, setCurrentStage ] = useState(stageName)

  const updateStageName: TourStageUpdateFn = ({current, next}) => {
    setCurrentStage(() => {
      CurrentUserState.tourStage[current] = true
      updateUser({ variables: { input: {onboardingProgress:  CurrentUserState.tourStage}} })
      CurrentUserState.currentTourStage = next
      return next
    })
  }
  return [currentStage, updateStageName]
}
