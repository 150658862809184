import { FC, memo, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useSnapshot } from 'valtio'
import { useNavigate, createSearchParams } from 'react-router-dom'
import Avatar from '../Avatar'
import ContextMenu from '../Modal/ContextMenu'
import Button from '../Button'
import { Icon, IconColor, IconSize, IconTypes } from '../Icon'
import Confirmation from '../../ui/Modal/Confirmation'

import {
  ChannelDetailedDocument,
  ClickAnonymousDocument,
  PinContentDocument,
  TipResult,
  TypesOfContent,
} from '@app/graphql'
import { DateTimeHelper, FeedHelper } from '@app/helpers'
import {
  ButtonSize,
  ButtonType,
  ContentUserData,
  AllUserRoles,
} from '@app/types'

import {
  CurrentUserState,
  PrivateChannelState,
  PublicChannelState,
  PostTipresultUpdateState,
} from '@app/storage'
import {
  INCOGNITO_AVATAR_IMAGE,
  PUBLIC_CHANNEL_ID,
  POST_TIP_ACTIONS,
  ROUTES,
  SEARCH_PARAMS,
  USER_NO_PROFILE_PNG_IMAGE_SRC,
} from '@app/constants'

type UpdateResultModeFunction = (payload: string) => void;
interface FeedPostHeaderProps {
  id: string;
  user: ContentUserData;
  createdAt: Date;
  updatedAt: Date;
  type: TypesOfContent;
  isPrivate: boolean;
  noActions: boolean;
  bookieName?: string;
  status?: TipResult;
  onMenuOpen?: () => void;
  editMode?: () => void;
  onDelete?: () => void;
  pinContentAccess?: AllUserRoles[];
  onUpdateResultMode?: UpdateResultModeFunction;
  isPostTip: boolean;
}

const FeedPostHeader: FC<FeedPostHeaderProps> = ({
  id,
  user,
  createdAt,
  updatedAt,
  type,
  isPrivate,
  noActions,
  bookieName,
  status,
  editMode,
  onDelete,
  pinContentAccess,
  onUpdateResultMode,
  isPostTip,
}) => {
  const [ openMenu, setOpenMenu ] = useState(false)
  const [ openaDialog, setOpenaDialog ] = useState(false)
  //const [imageLoaded, setImageLoaded] = useState<boolean>(null)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id: channelId } = useSnapshot(PrivateChannelState)
  const { isPublic } = useSnapshot(PublicChannelState)
  const {
    permEditPost,
    userType,
    id: currentUserId,
  } = useSnapshot(CurrentUserState)
  const [ pinContent ] = useMutation(PinContentDocument)
  const [ getDetailedChannel ] = useLazyQuery(ChannelDetailedDocument, {
    fetchPolicy: 'network-only',
  })

  const [ clickAnonymous ] = useMutation(ClickAnonymousDocument)

  const onEditClick = () => {
    setOpenMenu(false)
    editMode()
  }

  const onDeleteClick = () => {
    setOpenMenu(false)
    onDelete()
  }

  const onPinClick = () => {
    setOpenMenu(false)
    pinContent({
      variables: {
        channelId: isPublic ? PUBLIC_CHANNEL_ID : channelId,
        contentId: id,
        contentType: type,
      },
    })
      .then(() =>
        getDetailedChannel({
          variables: {
            channelId: isPublic ? PUBLIC_CHANNEL_ID : channelId,
          },
        })
      )
      .then((res) => {
        const result = res.data.channelDetailed.pinnedContent
        isPublic
          ? (PublicChannelState.pinnedContent = result)
          : (PrivateChannelState.pinnedContent = result)
      })
  }
  const goToTipsterProfile = () => {
    if (isPrivate) {
      setOpenaDialog(true)
    }
    if (!noActions && !isPrivate && user?.id) {
      navigate({
        pathname: `${ROUTES.profile.short}/${user.id}`,
        search: `?${createSearchParams({
          [SEARCH_PARAMS.channelId]: channelId,
        })}`,
      })
    }
  }

  const getDate =  () => {
    if (!updatedAt) {
      return `${t('common.published')}: ${DateTimeHelper.getRelativeDate(createdAt).toLowerCase()}`
    }

    const isSameDate = createdAt.getTime() === updatedAt.getTime()
    const publishedText = t('common.published')
    const editedText = t('common.edited')

    const dateText = isSameDate
      ? ` ${DateTimeHelper.getRelativeDate(createdAt).toLowerCase()}`
      : ` ${DateTimeHelper.getRelativeDate(updatedAt).toLowerCase()}`

    return `${isSameDate ? publishedText : editedText}:${dateText}`
  }

  const handleUpdateResultMode = (payload: TipResult) => {
    onUpdateResultMode(status)
    PostTipresultUpdateState.currentStatus = status
    PostTipresultUpdateState.newStatus = payload
    setOpenMenu(false)
  }

  const handleUpdatePostTipStatus = (item) => {
    switch (item.id) {
      case 1:
        handleUpdateResultMode(TipResult.Won)
        break
      case 2:
        handleUpdateResultMode(TipResult.Lost)
        break
      default:
        handleUpdateResultMode(TipResult.Void)
        break
    }
  }

  return (
    <HeaderContainer>
      <Avatar
        imageUrl={ user?.avatarUrl ?? USER_NO_PROFILE_PNG_IMAGE_SRC}
        height={36}
        width={36}
        onClick={goToTipsterProfile}
      />
      <UserInfo>
        <div>
          {isPrivate || !user?.displayName
            ? `${t('common.private')} ${type.toLowerCase()}`
            : t(user?.displayName)}
        </div>
      </UserInfo>
      {!noActions && (
        <>
          <Button
            className="edit-button"
            type={ButtonType.transparent}
            size={ButtonSize.small}
            onClick={() => setOpenMenu(true)}
          >
            <Icon
              type={IconTypes.menuHorizontalIcon}
              color={IconColor.grayDarker}
              size={IconSize.xsmall}
            />
          </Button>

          {
            <ContextMenu open={openMenu} onClose={() => setOpenMenu(false)}>
              {permEditPost &&
                FeedHelper.checkContentEditing({
                  userType: userType as AllUserRoles,
                  currentUserId,
                  creatorId: user?.id,
                }) && (
                <Button
                  type={ButtonType.transparent}
                  onClick={onEditClick}
                  size={ButtonSize.small}
                >
                  <Icon
                    type={IconTypes.brush}
                    color={IconColor.grayDarker}
                    height={14}
                    width={14}
                  />
                  {t('common.edit')}
                </Button>
              )}
              {FeedHelper.checkContentDeletion({
                isPublic,
                userType: userType as AllUserRoles,
                currentUserId,
                creatorId: user?.id,
              }) ? (
                  <Button
                    type={ButtonType.transparent}
                    onClick={onDeleteClick}
                    size={ButtonSize.small}
                  >
                    <Icon
                      type={IconTypes.trashBin}
                      color={IconColor.grayDarker}
                      height={14}
                      width={14}
                    />
                    {t('common.delete')}
                  </Button>
                ) : null}
              <CustomBookieName>{bookieName}</CustomBookieName>
            </ContextMenu>
          }
        </>
      )}
      {!noActions && (
        <>
          <ContextMenu open={openMenu} onClose={() => setOpenMenu(false)}>
            {permEditPost &&
              FeedHelper.checkContentEditing({
                userType: userType as AllUserRoles,
                currentUserId,
                creatorId: user?.id,
              }) && (
              <>
                {isPostTip &&
                    POST_TIP_ACTIONS.map((item) => (
                      <Button
                        type={ButtonType.transparent}
                        onClick={() => handleUpdatePostTipStatus(item)}
                        size={ButtonSize.small}
                        key={item.id}
                      >
                        {t(item.title)}
                      </Button>
                    ))}
                <Button
                  type={ButtonType.transparent}
                  onClick={onEditClick}
                  size={ButtonSize.small}
                >
                  <Icon
                    type={IconTypes.brush}
                    color={IconColor.grayDarker}
                    height={14}
                    width={14}
                  />
                  {t('common.edit')}
                </Button>
              </>
            )}

            {pinContentAccess?.includes(userType) && (
              <Button
                className="edit-button"
                type={ButtonType.transparent}
                onClick={onPinClick}
                size={ButtonSize.small}
              >
                <Icon
                  type={IconTypes.pin}
                  color={IconColor.grayDarker}
                  height={14}
                  width={14}
                />
                {t('feed.pinToTop')}
              </Button>
            )}

            {FeedHelper.checkContentDeletion({
              isPublic,
              userType: userType as AllUserRoles,
              currentUserId,
              creatorId: user?.id,
            }) ? (
                <Button
                  type={ButtonType.transparent}
                  onClick={onDeleteClick}
                  size={ButtonSize.small}
                >
                  <Icon
                    type={IconTypes.trashBin}
                    color={IconColor.grayDarker}
                    height={14}
                    width={14}
                  />
                  {t('common.delete')}
                </Button>
              ) : null}
          </ContextMenu>
        </>
      )}
      <Confirmation
        customAction={true}
        customActionConfirm={t('validation.meh')}
        customActionCancel={t('validation.alright')}
        open={openaDialog}
        onClose={() => setOpenaDialog(false)}
        onConfirm={() => {
          clickAnonymous({ variables: {input: user.id} })
          setOpenaDialog(false)
        }}
        confirmButtonType={ButtonType.primary}
        headerText={t('common.attention').toUpperCase()}
        icon={<Icon type={IconTypes.info} color={IconColor.warning} />}
      >
        <TextContainer>{t('validation.anonymousClicked')}</TextContainer>
      </Confirmation>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  align-items: flex-start;
  display: flex;
  // border-bottom: 1px solid var(--color-gray-light);
  height: 48px;
  margin: 0.5rem 0;
  position: relative;

  .follow-button {
    display: inline;
    background-color: transparent;
    font-weight: 500;
    color: var(--color-gray-darker);
    font-size: var(--font-size-small);
    margin-left: 0.5rem;
    padding: 0;
  }

  .edit-button {
    height: 18px;
    margin-left: 0.25rem;
  }
`

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: var(--font-size-small);
  font-weight: bold;
  color: var(--r-color-black);
  padding-left: 0.5rem;

  div {
    display: inline;
    text-align: start;
    justify-content: center;
    margin-top: 6px;
  }

  p {
    font-weight: 400;
    color: var(--color-gray-darker);
    font-size: var(--font-size-extra-small);
    text-align: left;
    margin-top: 2px;
    white-space: pre;
  }
`

const CustomBookieName = styled.span`
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: var(--color-gray-darker);
  font-size: var(--font-size-extra-small);
  max-width: 120px;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
  text-align: center;
`

const BookieContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  p {
    color: #4d4d4d;
    font-weight: 400;
    font-size: var(--font-size-extra-small);
    text-align: center;
  }
`

const TextContainer = styled.p`
  text-align: center;
`
export default memo(FeedPostHeader)
