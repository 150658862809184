let devEnvironment: Partial<IEnvironment> = {}
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  try { devEnvironment = require('./environment.dev.ts').default } catch(e) { /**/ }
}

const SUPPORTED_LANGUAGES = (process.env.REACT_APP_SUPPORTED_LANGUAGES ?? 'en').split(',').map(id => id.trim())

const environment = {
  API_URL: process.env.REACT_APP_API_URL || '',
  API_URL_IMAGE_UPLOAD: process.env.REACT_APP_API_URL_IMAGE_UPLOAD || '',
  SUBSCRIPTION_URL: process.env.SUBSCRIPTION_URL || '',
  AWS_PROJECT_REGION: process.env.REACT_APP_AWS_PROJECT_REGION || '',
  AWS_COGNITO_IDENTITY_POOL_ID: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID || '',
  AWS_COGNITO_REGION: process.env.REACT_APP_AWS_COGNITO_REGION || '',
  AWS_USER_POOLS_ID: process.env.REACT_APP_AWS_USER_POOLS_ID || '',
  AWS_USER_POOLS_WEB_CLIENT_ID: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID || '',
  AWS_OAUTH_DOMAIN: process.env.REACT_APP_AWS_OAUTH_DOMAIN || '',
  AWS_OAUTH_REDIRECT_SIGN_IN: process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGN_IN || 'https://www.votebetting.app/',
  AWS_OAUTH_REDIRECT_SIGN_OUT: process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGN_OUT || 'https://www.votebetting.app/',
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY || '',
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
  FIREBASE_MESSAGING_SENDER_ID: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID || '',
  FIREBASE_MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || '',
  FIREBASE_VAPID_KEY: process.env.REACT_APP_FIREBASE_VAPID_KEY || '',
  APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT || '',
  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY || '',
  TELEGRAM_BOT_ID: process.env.REACT_APP_TELEGRAM_BOT_ID || '',
  TELEGRAM_BOT_USERNAME: process.env.REACT_APP_TELEGRAM_BOT_USERNAME || '',
  SUPPORTED_LANGUAGES: SUPPORTED_LANGUAGES.length? SUPPORTED_LANGUAGES : [ 'en' ],
  PUBLIC_POSTHOG_KEY: process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
  PUBLIC_POSTHOG_HOST: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  TIPSTER_APP_TOUR_BASIC_EXPLAINER_VIDEO: process.env.REACT_APP_TIPSTER_APP_TOUR_BASIC_EXPLAINER_VIDEO || '',
  TIPSTER_APP_TOUR_CONTENT_EXPLAINER_VIDEO: process.env.REACT_APP_TIPSTER_APP_TOUR_CONTENT_EXPLAINER_VIDEO || '',
  TIPSTER_APP_TOUR_SUBSCRIPTION_EXPLAINER_VIDEO: process.env.REACT_APP_TIPSTER_APP_TOUR_SUBSCRIPTION_EXPLAINER_VIDEO || '',
  CONTENTFUL_ACCESS_TOKEN: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  CONTENTFUL_SPACE_ID: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  CONTENTFUL_FAQ_ENTRY_ID: process.env.REACT_APP_CONTENTFUL_FAQ_ENTRY_ID,
}

export type IEnvironment = typeof environment
export default { ...environment, ...devEnvironment }