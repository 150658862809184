import { proxy } from 'valtio'

import { CurrentUserStateType,TourStages } from '@app/types'
import { UserOnboardingStages, UserRoles } from '@app/graphql'

const initialTourStage: TourStages = {
  basicWelcome: false,
  basicVideoExplainer: false,
  contentWelcome: false,
  contentVideoExplainer: false,
  contentTipComparison: false,
  contentTipMenu: false,
  ContentDataTip: false,
  ContentImageTip: false,
  subscriptionMenu: false,
  subscriptionWelcome: false,
  subscriptionVideoExplainer: false,
  subcriptionServiceMenu: false,
  subscriptionFeatureBanner: false,
  userInviteWelcome: false,
  userInviteMenu: false,
  startAppBanner: false,
}

const initialState: CurrentUserStateType = {
  hasPendingSubscription: false,
  initiateOnboarding: false,
  avatarUrl: '',
  authBySocial: false,
  bio: '',
  birthday: null,
  confirmedConditions: false,
  countryId: '',
  id: null,
  incognitoMode: false,
  name: '',
  phone: '',
  profileBackgroundUrl: '',
  surname: '',
  userEmail: '',
  userType: null,
  username: '',
  gender: null,
  banned: false,
  muted: false,
  notedAboutMute: false,
  permAddPost: false,
  permEditPost: false,
  onboardingStage: null,
  ownedChannels: [],
  auth_type: null,
  unitValue: 0,
  unitShare: 0,
  tourStage: initialTourStage,
  isTourCompleted: false,
  currentTourStage: 'basicWelcome',

  get isTouring() {
    if(this.guestMode || !this.isTipster) return false
    if(this.tourStage.startAppBanner) return false
    if(localStorage.getItem('exitAppTour')) return false
    return !Object.values(this.tourStage).every(stage => stage)
  },

  get guestMode() {
    if(sessionStorage.getItem('incognitoMode')) {
      activateGuestMode()
    }
    return this.incognitoMode
  },
  get isTipster() {
    return this.userType === UserRoles.Tipster
  },
  get hasOnboarded() {
    return this.onboardingStage === UserOnboardingStages.Onboarded
  }}

export const CurrentUserState = proxy<CurrentUserStateType>(initialState)

export const resetCurrentUserState = () => {
  Object.keys(initialState).forEach(key => {
    CurrentUserState[key] = initialState[key]
  })
}

export const activateGuestMode = () => {
  CurrentUserState.confirmedConditions = true
  CurrentUserState.username = 'incognito.user.displayName'
  CurrentUserState.incognitoMode = true
  CurrentUserState.userType = (
    localStorage.getItem('onboardingFlow') === UserRoles.Tipster?
      UserRoles.Tipster: UserRoles.User
  )
  CurrentUserState.onboardingStage = UserOnboardingStages.Onboarded
  sessionStorage.setItem('incognitoMode', 'true')
}

export const exitAppTour = () => {
  localStorage.setItem('exitAppTour', '1')
  CurrentUserState.currentTourStage = 'startAppBanner'
}

export const resumeAppTour = () => {
  localStorage.removeItem('exitAppTour')
  CurrentUserState.currentTourStage = 'basicWelcome'
}

