import { lazy } from 'react'

export const AmountInfo =  lazy(() => import('./AmountInfo'))
export const Avatar =  lazy(() => import('./Avatar'))
export const BorderLoader =  lazy(() => import('./BorderLoader'))
export const Button =  lazy(() => import('./Button'))
export const Checkbox =  lazy(() => import('./Checkbox'))
export const Datepicker =  lazy(() => import('./Datepicker'))
export const Divider =  lazy(() => import('./Divider'))
export const DividerWithText =  lazy(() => import('./DividerWithText'))
export const DividerText =  lazy(() => import('./DividerText'))
export const Dropdown =  lazy(() => import('./Dropdown'))
export const GiphyGridPanel =  lazy(() => import('./GiphyGridPanel'))
export const Input =  lazy(() => import('./Input'))
export const InputRange =  lazy(() => import('./InputRange'))
export const RadioButton =  lazy(() => import('./RadioButton'))
export const ScrollContainer =  lazy(() => import('./ScrollContainer'))
export const BetaOverlay =  lazy(() => import('./BetaOverlay'))
export const SearchInput =  lazy(() => import('./SearchInput'))
export const SearchWithAutocomplete =  lazy(() => import('./SearchWithAutocomplete'))
export const SelectableList =  lazy(() => import('./SelectableList'))
export const SettingsBlockHeader =  lazy(() => import('./SettingsBlockHeader'))
export const Switcher =  lazy(() => import('./Switcher'))
export const Tabs =  lazy(() => import('./Tabs'))
export const Textarea =  lazy(() => import('./Textarea'))
export const TipsterMenu =  lazy(() => import('./TipsterMenu'))
export const TipsterSubscriptionMenu =  lazy(() => import('./TipsterSubscriptionMenu'))
export const UploadInput =  lazy(() => import('./UploadInput'))
export const PopupInfo =  lazy(() => import('./PopupInfo'))

export * from './Feed'
export * from './Icon'
export * from './Modal'
export * from './Tour'
export * from './Tip'
export { RichUploadInput } from './UploadInput'
export { default as Loader } from './Loader'
