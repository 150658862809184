let request: IDBOpenDBRequest
let db: IDBDatabase
let version = 1
const dbName = 'vb-store'


export enum Stores {
  PUBLIC_FEED = 'public_feed',
  PRIVATE_FEED = 'private_feed',
  MATCHES = 'matches'
}

export const initDB = (configs: {storeName: string, keyPath: string}[]): Promise<boolean> => {
  return new Promise((resolve) => {
    // open the connection
    request = indexedDB.open(dbName)

    request.onupgradeneeded = (event: any) => {
      db = event.target.result
      for (const {storeName, keyPath} of configs) {
        // if the data object store doesn't exist, create it
        if (!db?.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, {keyPath})
        }
        // no need to resolve here
      }
    }

    request.onsuccess = (event: any) => {
      db = event.target.result
      version = db.version
      resolve(true)
    }

    request.onerror = () => {
      resolve(false)
    }
  })
}

export const addData = <T>(storeName: string, data: T): Promise<T|string|null> => {
  return new Promise((resolve) => {
    request = indexedDB.open(dbName)
    request.onsuccess = (event: any) => {
      db = event.target.result
      if(!db.objectStoreNames.contains(storeName)) return resolve(null)
      const tx = db.transaction(storeName, 'readwrite')
      const store = tx.objectStore(storeName)
      store.put(data)
      resolve(data)
    }

    request.onerror = () => {
      const error = request.error?.message
      if (error) {
        resolve(error)
      } else {
        resolve('Unknown error')
      }
    }
  })
}

export const getStoreData = <T>(storeName: Stores, key): Promise<T[]> => {
  return new Promise((resolve) => {
    request = indexedDB.open(dbName)

    request.onsuccess = (event: any) => {
      db = event.target.result
      if(!db.objectStoreNames.contains(storeName)) return resolve(null)
      const tx = db.transaction(storeName, 'readonly')
      const store = tx.objectStore(storeName)
      const res = store.get(key)
      res.onsuccess = () => {
        resolve(res.result)
      }
    }
  })
}

export const checkDataExist = <T>(storeName: Stores, key): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    request = indexedDB.open(dbName)

    request.onsuccess = (event: any) => {
      db = event.target.result
      if(!db.objectStoreNames.contains(storeName)) return resolve(false)
      const tx = db.transaction(storeName, 'readonly')
      const store = tx.objectStore(storeName)
      const res = store.getKey(key)
      res.onsuccess = () => {
        resolve(!!res.result)
      }
    }
    request.onerror = () => reject(false)
  })
}

export const deleteData = (storeName: string, key: string): Promise<boolean> => {
  return new Promise((resolve) => {
    // again open the connection
    request = indexedDB.open(dbName)

    request.onsuccess = (event: any) => {
      db = event.target.result
      if(!db.objectStoreNames.contains(storeName)) return resolve(false)
      const tx = db.transaction(storeName, 'readwrite')
      const store = tx.objectStore(storeName)
      const res = store.delete(key)

      // add listeners that will resolve the Promise
      res.onsuccess = () => {
        resolve(true)
      }
      res.onerror = () => {
        resolve(false)
      }
    }
  })
}
