import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import env from '@app/environment'

import Screen from '@app/components/Screen/Screen'
import { InstallIOSPopup } from '@app/components'
import {
  Button,
  Divider,
  Icon,
  IconColor,
  IconSize,
  IconTypes,
  Modal,
} from '@app/ui'
import {
  BrowserMapInitialKey,
  BrowsersMap,
  LOGOTYPE_IMAGE_SRC,
  ROUTES,
} from '@app/constants'
import { ButtonSize, ButtonType } from '@app/types'
import { InstallAppContext } from '@app/context'
import { CurrentUserState } from '@app/storage'
import { requestNotificationPermissions } from '@app/firebase'
import { UserRoles } from '@app/graphql'
import { renderToStaticMarkup } from 'react-dom/server'
import { getToken } from '@app/helpers/auth'
import { useSnapshot } from 'valtio'
import { CalendarHelper } from '@app/helpers'
import { initDB, Stores } from '@app/helpers/db'


interface EntryScreenProps { }

interface DomainTitleProps {
  weight?: number;
  size?: number;
  lineHeight?: number;
  paddingTop?: number;
  fontStyle?: string
}

export const DomainTitleComponent: React.FC<DomainTitleProps> = ({ weight, size, lineHeight, paddingTop, fontStyle }) => {
  const { t } = useTranslation()
  return (<DomainTitle
    weight={weight}
    size={size}
    paddingTop={paddingTop}
    lineHeight={lineHeight}
    fontStyle={fontStyle}>
    <Divider size="small" />
    {t('login.domaiN')}
  </DomainTitle>
  )
}

const EntryScreen: React.FC<EntryScreenProps> = () => {
  const {id: userId} = useSnapshot(CurrentUserState)
  const { t } = useTranslation()
  const [ currentBrowser, setCurrentBrowser ] = useState(BrowserMapInitialKey)
  const [ openInstallIOSModal, setOpenInstallIOSModal ] = useState(false)
  const [ openInstallModal, setOpenInstallModal ] = useState(false)
  const { search: queryParams } = useLocation()
  const { event: installEvent } = useContext(InstallAppContext)
  const navigate = useNavigate()

  const onCloseInstallIOSModal = () => setOpenInstallIOSModal(false)
  const onCloseInstallModal = () => setOpenInstallModal(false)
  const installApp = async () => {
    try {
      await installEvent.prompt()
      await installEvent.userChoice
    } finally {
      setOpenInstallModal(false)
    }
  }
  const onChangeBrowser = (id) => () => {
    if (id) setCurrentBrowser(id)
  }
  const onStart = (isTipster: boolean) => {
    if(isTipster){
      localStorage.setItem('onboardingFlow', UserRoles.Tipster)
    } else {
      localStorage.setItem('onboardingFlow', UserRoles.User)
    }
    localStorage.getItem('onboardingFlow') === UserRoles.Tipster || isTipster
      ? navigate(ROUTES.tipsterOnboarding.short + (queryParams || ''))
      : navigate(ROUTES.login.short + (queryParams || ''))
    requestNotificationPermissions()
  }

  useEffect(() => {
    const publicFeedworker = new Worker(new URL('@app/workers/feed/public.ts', import.meta.url))
    async function publicFeedPostMessage() {
      const authType = (localStorage.getItem('auth_type') || '').trim().toLowerCase()
      const incognitoMode = sessionStorage.getItem('incognitoMode') ?? ''
      const token = await getToken()
      const tokenType = authType === 'telegram' ? 'Telegram' : authType === 'magic_link' ? 'MagicLink' : 'Bearer'
      for(const skip of [0, 1, 2,]) {
        publicFeedworker.postMessage({
          headers: {
            authorization:  `${tokenType} ${token}`,
            'X-Incognito-Mode': incognitoMode,
          },
          variables: {
            skip: skip * 10,
            limit: 10,
          },
          url: env.API_URL,
        })
      }
    }
    const worker = new Worker(new URL('@app/workers/feed/matchList.ts', import.meta.url))
    async function matchListPostMessage() {
      const authType = (localStorage.getItem('auth_type') || '').trim().toLowerCase()
      const incognitoMode = sessionStorage.getItem('incognitoMode') ?? ''
      const token = await getToken()
      const tokenType = authType === 'telegram' ? 'Telegram' : authType === 'magic_link' ? 'MagicLink' : 'Bearer'
      for(const skip of [0, 1, 2,]) {
        worker.postMessage({
          headers: {
            authorization:  `${tokenType} ${token}`,
            'X-Incognito-Mode': incognitoMode,
          },
          variables: {
            skip: skip * 10,
            limit: 10,
            eventsDate: CalendarHelper.getDayAsString(new Date()),
            forDefaultBookie: true,
            sportId: '1',
          },
          url: env.API_URL,
        })
      }
    }
    async function runWorkers() {
      await initDB([{storeName: Stores.PUBLIC_FEED, keyPath: 'cursor'}, {storeName: Stores.MATCHES, keyPath: 'cursor'}])
      publicFeedPostMessage()
      matchListPostMessage()
    }
    runWorkers()
    return () => {
      worker.terminate()
      publicFeedworker.terminate()
    }
  }, [])
  const browserVideo = BrowsersMap.get(currentBrowser)
  const svgString = encodeURIComponent(renderToStaticMarkup(<Watermark color={IconColor.brand} />))

  return (
    <Container>
      <WatermarkWrapper>
        <Icon type={IconTypes.waterMarkIcon} />
      </WatermarkWrapper>
      <EntryScreenStyled
        title={t('common.welcome')}
      >
        <Divider size={'custom'} customSize={250}/>
        <picture>
          <source
            type="image/webp"
            srcSet={LOGOTYPE_IMAGE_SRC}
          />
          <img
            className="logotype"
            src={LOGOTYPE_IMAGE_SRC}
            alt="Votebetting"
          />
        </picture>
        <Divider size={'big'}/>
        <DomainTitleComponent/>
        <Divider size={'big'}/>

        <DomainSubtitle>{t('login.poweringTipsters')}</DomainSubtitle>
        <DomainSubtitle>{t('login.sloganUsersShort')}</DomainSubtitle>
        <Divider />
        <Divider expanded />
        <div className="">
          <StyledStartButton
            size={ButtonSize.big}
            type={ButtonType.white}
            onClick={() => onStart(true)}
            fluid
          >
            <p className="title">{t('entry.iamTipster')}</p>
          </StyledStartButton>

          <StyledStartButton
            size={ButtonSize.big}
            type={ButtonType.white}
            onClick={() => onStart(false)}
            fluid
          >
            <p className="title">{t('entry.iamUser')}</p>
          </StyledStartButton>
        </div>
        <Divider size="huge" />

        {/* MODALS */}
        {openInstallModal && (
          <StyledModal open onClose={onCloseInstallModal}>
            <div className="install-content vb-flex-column-fs-c">
              <StyledModalHeader className="vb-flex-row-spb-c">
                <button
                  className="left-arrow vb-flex-row-fs-c"
                  onClick={onChangeBrowser(browserVideo.prevId)}
                >
                  {browserVideo?.prevId ? (
                    <Icon
                      type={IconTypes.arrowLeft}
                      color={IconColor.brandDark}
                      size={IconSize.large}
                    />
                  ) : null}
                </button>
                <p className="vb-font big w700 brandDarker">
                  {browserVideo.name}
                </p>
                <button
                  className="right-arrow vb-flex-row-fs-c "
                  onClick={onChangeBrowser(browserVideo.nextId)}
                >
                  {browserVideo?.nextId ? (
                    <Icon
                      type={IconTypes.arrowLeft}
                      color={IconColor.brandDark}
                      size={IconSize.large}
                    />
                  ) : null}
                </button>
              </StyledModalHeader>
              <div className="install-demo">
                <video controls muted playsInline>
                  <source src={browserVideo.videoUrl} type="video/mp4" />
                </video>
              </div>
              <div className="buttons-block vb-flex-row-spb-c">
                <Button
                  size={ButtonSize.normal}
                  className="vb-font w700"
                  onClick={onCloseInstallModal}
                >
                  {t('login.notNow')}
                </Button>
                <Button
                  className="vb-font w700"
                  size={ButtonSize.normal}
                  type={ButtonType.primary}
                  onClick={installApp}
                >
                  {t('login.installApp')}
                </Button>
              </div>
            </div>
          </StyledModal>
        )}
        <InstallIOSPopup
          open={openInstallIOSModal}
          onClose={onCloseInstallIOSModal}
        />
      </EntryScreenStyled>
    </Container>
  )
}

const WatermarkWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100%;
  width: 100%;

  svg {
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }
`
const StyledStartButton = styled(Button)`
  margin: 5px 10px;
  border-radius: 25px;
  box-shadow: none;
  padding: 0rem 0.5rem;

  .title {
    padding: 0 0px;
      color: black;
      font-size: 16px;


  }
 &&.type-white {
    background-color: white;
    box-shadow: none;
  }

  width: 180px;

 @media only screen and (max-width: 768px){   
  margin: 0px;
   margin-top: 5%;

    &&.size-big {
    font-size: var(--font-size-big);
    font-weight: bold;
    padding: 0.5rem 0.5rem;
  }
  }
`

const DomainTitle = styled.p<DomainTitleProps>`
  font-weight:  ${(props) => (props.weight ? props.weight : 900)};
  font-style:   ${(props) => (props.fontStyle ? props.fontStyle : 'italic')};
  font-size:    ${(props) => (props.size ? props.size : 30.41)}px;
  font-family:  Nunito-Bold;
  line-height:  ${(props) => (props.lineHeight ? props.lineHeight : 34.65)}px; 
  padding: 20px 0px;

    @media only screen and (max-width: 375px) and (max-height: 667px) {
      display: none;
    }

`

const DomainSubtitle = styled.p`
  text-align: center;
  font-size:  16px;
  color: var(--r-color-logo-green);
  font-weight: 600;
  line-height: normal;
  font-style: italic;
`

const EntryScreenStyled = styled(Screen)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  text-align: center;
  background-size: contain;
  height: 100vh;

  .logotype {
    width: 196px;
    height: 171px;
    display: block;
    margin: auto;
  }
`

const StyledModal = styled(Modal)`
  height: 100%;

  .modal-content {
    padding: 0 16px;
    height: 80%;
  }
  .install-content {
    height: 100%;
  }
  .install-demo {
    max-height: calc(100% - 120px);
    padding: 16px 0;
    flex: 1;

    video {
      height: 100%;
      object-fit: contain;
    }
  }
  .buttons-block {
    width: 100%;
  }
`

const StyledModalHeader = styled.div`
  height: 60px;
  width: 100%;

  .left-arrow,
  .right-arrow {
    min-width: 90px;
    background-color: transparent;
    border: none;
    padding: 0 24px;
  }
  .right-arrow {
    transform: rotateY(180deg);
    transform-origin: center center;
  }
`

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  border: 1px solid #ccc;
  overflow: hidden;
  background-color: #E2E7ED;
`

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 20px;
  font-size: 18px;
`

const Watermark = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0.3;
  z-index: 0;
 background-size: cover;
`
export default EntryScreen