import { FC, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Screen } from '@app/components'
import { Button, Divider, Icon, IconColor, IconSize, IconTypes, Popup } from '@app/ui'
import { Trans, useTranslation } from 'react-i18next'
import { DomainTitleComponent } from './Entry'
import Input from '@app/ui/Input'
import { ButtonSize, ButtonType } from '@app/types'
import { getFirebaseAuth } from '@app/firebase'
import { ROUTES } from '@app/constants'
import { useNavigate } from 'react-router-dom'

interface MagicLinkScreenProps { }

const MagicLinkScreen: FC<MagicLinkScreenProps> = () => {

  const navigate = useNavigate()

  const { t } = useTranslation()

  const [ emailText, setEmailText ] = useState('')
  const [ showPopup, setShowPopup ] = useState(false)

  const onEmailChange = (text = '') => setEmailText(text)


  useEffect(() => {
    getFirebaseAuth()
  }, [])


  const handleClosePopUp = () => {
    setShowPopup(false)
    navigate(ROUTES.login.short)
    window.location.reload()

  }
  return (
    <>
      <LoginScreenStyled title={t('login.authorization')}>
        <Divider size="logoTopSpace" />
        <ImageIcon className="logotype" src="/assets/branding/logotype-entry-x.png" alt="Votebetting" />
        <DomainTitleComponent />
        <Divider size="normal" />

        <CreateAccount>{t('auth.createYourAccount')}</CreateAccount>
        <Input
          label={t('common.email')}
          placeholder={t('auth.enterEmail')}
          value={emailText}
          required
          onChange={onEmailChange}
          containerMargin={'0.2rem'}
        />
        <Divider size="small" />
        <MagicLinkDescription>{t('auth.useMagicLink.description')}</MagicLinkDescription>
        <Divider size="big" />
        <Button
          size={ButtonSize.big}
          type={ButtonType.buttonColor}
          fluid
          onClick={() => console.log('')}
          textColor="var(--r-color-white)"
        >
          <ButtonContent>
            <Icon type={IconTypes.magicLinkIcon} color={IconColor.white} size={IconSize.small} />
            <p><Trans>{t('auth.magicLink.send')}</Trans></p>
          </ButtonContent>
        </Button>
        <Divider size="big" />


        <Divider expanded />
        <ButtonContainer>
          <Icon type={IconTypes.angleLeft}
            color={IconColor.buttonColor}
            size={IconSize.small} />
          <Button
            onClick={() => navigate(-1) }
            type={ButtonType.transparent}>
            <p>Back</p>
          </Button>
        </ButtonContainer>
      </LoginScreenStyled>

      {/* <Popup
        open={showPopup}
        onClose={null}
        closeButtonText={t('check.inbox')}
        otherMessageParam={t('check.inbox.description', { text: t('auth.sendAgain')})}
        otherMessageParamOnClick={() => { handleSendMagicLink() }}
        buttonFluid={true}
        headerText={t('auth.checkYourEmail').toUpperCase()}
        hideActionButton={false}
      >
        <p onClick={handleClosePopUp }>{t('validation.weEmailMagicLink', { email: emailText })}</p>
      </Popup> */}
    </>
  )
}

const LoginScreenStyled = styled(Screen)`
  text-align: center;
  background-color: var(--r-color-background-gray);

  .logotype {
    width: 180px;
    display: block;
    margin: auto;
  }
  .incognito-icon {
    width: 32px;
  }
  iframe {
    display: none !important;
  }
`

const ImageIcon = styled.img`
  padding: 20px;

`
const LoginAction = styled.p`
  padding: 20px 0;
`

const DomainTitle = styled.p`
  font-weight:  900;
  font-style:   italic;
  font-size:    30.41px;
  font-family:  Nunito-Bold;
  line-height:  34.65px; 

`
const MagicLinkDescription = styled.p`
  text-align: left;
  font-size: var(--font-size-extra-small);
`
const CreateAccount = styled.p`
  text-align: center;
  font-size: var(--font-size-big);
  color: var(--r-color-gray-darker);
  font-weight: 700;
  padding: 20px 0;
`
const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    padding-left: 10px;
      font-size: var(--font-size-medium);
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: var(--font-size-medium);
    font-weight: 900;
  }
`
export default MagicLinkScreen


